<template>
    <div>
        <round-header
          v-model = "currentRound"
          :showElementsToCorrect="true"
          :showBackButton = "true"
        />

        <fieldset >
          <legend>Généralités et valeurs par défaut</legend>
          <b-form-group label="Date de la manche">
            <b-form-datepicker v-model="date" />
          </b-form-group>

          <b-form-group label="Chef juge de la manche">
            <b-select v-model="chiefJudge" >
              <option v-for="judge in presentJudges" :key="judge.id" :value="judge.staff_id">{{ judge.STAFF_MEMBER.PEOPLE.name }}</option>
            </b-select>
          </b-form-group>

          <b-form-group label="Ordre de passage des compétiteurs">
            <b-radio-group plain
              v-model="competitorsOrderRule"
              buttons
              button-variant="outline-secondary"
            >
              <b-form-radio :value="ExecOrderRuleEnum.Alea">&nbsp;<b-icon icon="dice5" size="lg" />&nbsp;Aléatoire</b-form-radio>
              <b-form-radio :value="ExecOrderRuleEnum.PrevRoundReverse" v-if="currentRound.round_number > 1">&nbsp;<b-icon icon="sort-up" size="sm" />&nbsp;Ordre inverse de la manche précédente</b-form-radio>
              <b-form-radio :value="ExecOrderRuleEnum.Rank" v-if="currentRound.round_number > 1">&nbsp;<b-icon icon="sort-numeric-down" size="sm" />&nbsp;Classement de la manche précédente (1..n)</b-form-radio>
              <b-form-radio :value="ExecOrderRuleEnum.RankReverse" v-if="currentRound.round_number > 1">&nbsp;<b-icon icon="sort-numeric-down-alt" size="sm" />Classement inverse de la manche précédente (n..1)</b-form-radio>
              <b-form-radio :value="ExecOrderRuleEnum.Subscription">&nbsp;<b-icon icon="list-ol" size="sm"/>Ordre des inscriptions</b-form-radio>
              <b-form-radio :value="ExecOrderRuleEnum.Alpha">&nbsp;<b-icon icon="sort-alpha-down" size="sm" />&nbsp;Ordre alphabétique</b-form-radio>
              <b-form-radio :value="ExecOrderRuleEnum.AlphaReverse">&nbsp;<b-icon icon="sort-alpha-down-alt" size="sm"/>&nbsp;Ordre alphabétique inversé</b-form-radio>
            </b-radio-group>
          </b-form-group>
          <b-form-group label="Règles de lissage des notes" v-if="false">
            <b-checkbox switch v-model="applySkipWorseNoteRule">Eliminer la moins bonne note</b-checkbox>
            <b-checkbox switch v-model="applySkipBestNoteRule">Eliminer la meilleure note</b-checkbox>
          </b-form-group>
        </fieldset>

       <!-- <level-categories-summary
          v-for="level in levels"
          :key="level.id"
          :level="level.LEVEL.name"
        >-->
        <hr />
        <fieldset>
          <legend>Niveau(x) &amp; Catégorie(s)</legend>
        <b-tabs card>
          <b-tab v-for="level in levels" :key="level.id" :title="level.LEVEL.name">

<!--          <template v-slot:header><hr class="headerFooter"></template>-->

          <fieldset class="fieldset">
            <legend>Règles applicables</legend>
            <b-form-group label="Format">
              <round-rule-selector
                plain
                buttons
                button-variant="outline-secondary"
                :competition="currentCompetition"
                :round="currentRound"
                :level="level"
              />
            </b-form-group>
          </fieldset>

          <fieldset class="fieldset" v-if="seasonLevelRule(level).hasCompulsories">
            <legend>Figures imposées</legend>
            <b-tabs>
              <b-tab v-for="category in categories(level)" :key="category.CATEGORY.code" :title="category.CATEGORY.name">
                <template #title><icon-category size="sm" v-model="category.CATEGORY.code"/>&nbsp;{{category.CATEGORY.shortname}}</template>
                <level-category-compulsories-selector :category=category style="margin-top:10px;" />
              </b-tab>
            </b-tabs>
<!--            <div class="selector">
              <div v-for="category in categories(level)" :key="category.CATEGORY.code">
                <h4>{{ category.CATEGORY.name }}</h4>

                <level-category-compulsories-selector
                  :category = category
                />

              </div>
            </div>-->
          </fieldset>
        </b-tab>
      </b-tabs>
      </fieldset>
          <!--</level-categories-summary>-->


        <!--<hr/>-->


        <!--<hr/>-->

        <b-button v-if="!currentRound.isBriefingDone" @click="roundBriefing" :disabled="missingRequirements.length == 0">
            Démarrer le briefing pour lancer la manche
        </b-Button>

    <!--    <div
          v-for="level in levels"
          :key="'k' + level.code"
        >
          <div
          >
            <category-events-launcher
              v-for="category in categories(level)"
              :key="category.code"
              :category="category"
              @edit="editCategory"
              @prepareEvent="prepareEvent"
            />
          </div>
        </div>
-->
    </div>
</template>

<script>
import { mapMutations, /*mapGetters*/ } from "vuex";
import { RST_EVENT, RST_COMPETITION_STAFF, /*RST_COMPETITION, RST_ROUND, REF_LEVEL, REF_CATEGORY,*/ RST_CATEGORY, SEASON_RULE, SEASON_COMPULSORY, REF_COMPULSORY, RST_LEVEL, /*RST_ROUND_COMPULSORY*/ } from "@/LocalDB";
//import LevelCategoriesSummary from '@/components/LevelCategoriesSummary';
import RoundRuleSelector from '@/components/RoundRuleSelector';
import LevelCategoryCompulsoriesSelector from '@/components/LevelCategoryCompulsoriesSelector';
import RoundHeader from '@/components/RoundHeader';
////import CategoryEventsLauncher from '@/components/CategoryEventsLauncher';
import { CompetitorExecOrderRuleEnum } from '@/Constants';
import RoundsConfigurationHelper from '@/services/helpers/roundsConfigurationHelper';
import IconCategory from '@/components/IconCategory.vue';

export default {
  components: { RoundHeader, /*LevelCategoriesSummary,*/ RoundRuleSelector, LevelCategoryCompulsoriesSelector, IconCategory/*, CategoryEventsLauncher*/ },
    name: "Manche",
    data(){
        return {
            disabledHidden: false,
        }
    },
    computed:{
        round(){
            return this.currentRound;
        },
        ExecOrderRuleEnum(){
          console.log('ExecOrderRuleEnum', CompetitorExecOrderRuleEnum);
          return CompetitorExecOrderRuleEnum;
        },
        levels(){
          var ret = RST_LEVEL.query()
                    .where('competition_id', this.currentCompetition.id)
                    .where('round_number', this.currentRound_number)
                    //.where(item => item.level != null)
                    //.where('category', null)
                    .get()
                    .map(item => {
                      //item.LEVEL = REF_LEVEL.query().where("code", item.level).first();
                      return item;
                    })
                    .filter((value, index, self) => { return self.indexOf(value) === index})
          return ret;
        },
        missingRequirements(){
          return RoundsConfigurationHelper.missingRequirementsBeforeRun(this.currentRound_number);
        },
        date:{
          get(){ return this.round.date},
          set(newValue){
            this.$showRefresher()
              .then(() => {
                RoundsConfigurationHelper.changeDate(this.currentCompetition_id, this.currentRound_number, newValue);
                this.$hideRefresher();
              });
          }
        },
        competitorsOrderRule:{
          get(){ return this.round.competitors_order_rule},
          set(newValue){
            this.$showRefresher()
              .then(()=>{
                RoundsConfigurationHelper.changeExecutionOrderRule(this.currentCompetition_id, this.currentRound_number, newValue);
                this.$hideRefresher();
              });
          }
        },
        applySkipWorseNoteRule:{
          get(){ return this.round.applySkipWorseNoteRule },
          set(newValue){
            this.$showRefresher()
              .then(()=>{
                RoundsConfigurationHelper.changeApplySkipWorseNoteRule(this.currentCompetition_id, this.currentRound_number, newValue);
                this.$hideRefresher();
              });
          }
        },
        applySkipBestNoteRule:{
          get(){ return this.round.applySkipBestNoteRule },
          set(newValue){
            this.$showRefresher()
              .then(()=>{
                RoundsConfigurationHelper.changeApplySkipBestNoteRule(this.currentCompetition_id, this.currentRound_number, newValue);
                this.$hideRefresher();
              });
           }
        },
        chiefJudge:{
          get(){ return this.currentRound.chiefJudge_staff_id},
          set(newValue){
            this.$showRefresher()
              .then(() => {
                RoundsConfigurationHelper.changeRoundChiefJudge(this.currentCompetition_id, this.currentRound_number, newValue);
                this.$hideRefresher();
              })
          }
        },
        presentJudges(){
          var ret = RST_COMPETITION_STAFF.query()
                          .where("competition_id", this.currentCompetition_id)
                          .where("event", null)
//                          .where("isJudge", true)
                          .where(staffMember => { return staffMember.CAPABILITIES.isJudge})
                          .get();
          return ret;
        },
    },
    methods:{
        ...mapMutations('currentState', ['setCurrentLevelId', 'setCurrentCategoryId']),
        roundBriefing(){
          this.$showLoader()
            .then(() => {
              this.$router.push({ name: 'RoundBriefing' });
          });
        },

        categories(level){
          var ret = RST_CATEGORY.query()
                    .where('competition_id', this.currentCompetition.id)
                    .where('round_number', this.currentRound_number)
                    .where('level', level.code)
                    .where(item => item.COMPETITORS.length > 0)
                    .get()
                    .map(item => {
                      return item;
                    })
                    .filter((value, index, self) => { return self.indexOf(value) === index});
          return ret;
        },
        seasonLevelRule(rstLevel)
        {
          var ret = SEASON_RULE.query()
                  .where("competition_type", this.currentCompetition.competition_type)
                  .where("year", this.currentCompetition.year)
                  .where("level", rstLevel.LEVEL.code)
                  .where("rule", rstLevel.rule)
                  .first();
          return (ret != null) ? ret : { hasCompulsories: false };
        },
        figures(level, category)
        {
          var ret = SEASON_COMPULSORY.query()
                      .where('year', this.currentCompetition.year)
                      .get()
                      .map(item => {
                        return REF_COMPULSORY.query().where("id", item.compulsory_id).first();
                      })
                      .filter(item => item.category == category.code && item.level == level.LEVEL.code);
          return ret;
        },
        editCategory(category){
          this.setCurrentLevel_code(category.level);
          this.setCurrentCategory_code(category.category);
          console.log(this.currentCompetition, this.currentRound, this.currentLevel, this.currentCategory);
        },
        prepareEvent(event){
          this.$showLoader()
            .then(() => {
              this.setCurrentLevel_code(event.level);
              this.setCurrentCategory_code(event.category);
              this.setCurrentEvent_code(event.event);
              if(this.currentEvent == null)
              {
                var newId = this.newGuid();
                RST_EVENT.insertOrUpdate({
                  data: {
                          id: newId,
                          competition_id: event.competition_id,
                          round_number: event.round_number,
                          level: event.level,
                          category: event.category,
                          event: event.event,
                  }
                });
              }
              this.$router.push({ name: 'EventPrepare'});
          });
        },
        goToCategory(level, category){
          this.$showLoader()
            .then(() => {
              this.setCurrentLevelId(level.id);
              this.setCurrentCategoryId(category.id);
              this.$router.push({ name: 'Category'} );
            });
        },
    },
    mounted(){
      this.checkSynchro();
      this.$hideLoader();
    }
}
</script>

<style scoped>
    .location.selective { font-weight:bolder; font-size:1.4rem; }
    .roundNo { font-weight: bolder; font-size: 1.2rem; }
    .level { font-weight: bolder; margin-top: 1.2rem; margin-bottom:0.5rem; }
    .category { min-width:100px; max-width:100px; border-width:1px; border-style:solid; padding:2px; margin: 5px; border-radius: 5px;}
    .category.actif{ border-color: orangered ;}
    .category.inactif{ border-color: var(--ffvlInactif); }
    .inactif { color: var(--ffvlInactif); cursor: default; }
    .categories-list{
        display:flex;
        align-content: space-around;
        justify-content:center;
        flex-wrap: wrap;
    }
    .completed{ border:green 3px solid; background-color:lightgreen;}
</style>

<style>

    div.cdf{ margin-top: 5px;}
</style>
